import React from "react";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {vs as style} from 'react-syntax-highlighter/dist/esm/styles/prism';
import {graphql, useStaticQuery} from "gatsby";
import {MdFileDownload} from "react-icons/md";

function ExampleCodeSnippet({filename}) {
    const {allFile: {nodes}} = useStaticQuery(graphql`
query FileCodeSnippetQuery {
  allFile(filter: {sourceInstanceName: {eq: "cpp-examples"}}) {
    nodes {
      relativePath
      childCppExample {
        content
      }
    }
  }
}    
`);

    const {childCppExample: {content}} = nodes.find(({relativePath}) => relativePath === filename);

    return (
        <div className="d-flex flex-column">
            <CodeSnippet>{content}</CodeSnippet>
            <a className="ml-auto" href={`/Examples/${filename}`}>
                <MdFileDownload/>{' '}
                {filename}
            </a>
        </div>
    );
}

const CodeSnippet = ({className = '', children, language = "cpp"}) => (
    <div className={`${className} code-callout code-md-aligned-callout`}>
        <SyntaxHighlighter language={language}
                           style={style}
        >
            {children}
        </SyntaxHighlighter>
    </div>
);

export {
    CodeSnippet,
    ExampleCodeSnippet
};
